<template>
  <div class="page">
    <div class="dkzc-box">
      <span class="title">模拟考试</span>
      <div class="img">
        <!-- 广告轮播图 -->
        <advBanner
          :bannerArr="bannerArr"
          v-if="bannerArr.length > 0"
          carouselWidth="1200px"
          carouselHeight="470px"
        ></advBanner>
        <!-- 广告轮播图 -->
      </div>

      <div class="analogVolume" v-loading="loading">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="模拟卷" name="analogVolume">
            <div class="paperDivList">
              <div
                v-for="(item, index) in userExamListsList"
                :key="item.id"
                style="position: relative"
              >
                <div
                  class="chengImgDiv"
                  @click.stop="showPaperListView(index)"
                  v-show="!item.isShow"
                >
                  <img class="avatar" :src="item.close_cover" alt="" />
                </div>

                <div
                  class="chengImgDivTwo"
                  @click.stop="showPaperListView(index)"
                  v-show="item.isShow"
                >
                  <img class="avatar" :src="item.cover" alt="" />
                </div>

                <div class="paperListView" v-show="item.isShow">
                  <div
                    v-for="it in item.paperList"
                    :key="it.id"
                    class="paperList"
                    @click="
                      $router.push({
                        path: '/index-mockExam-answerSheet',
                        query: { serial_code: it.serial_code },
                      })
                    "
                  >
                    <div v-if="it.caption" class="title">
                      {{ it.caption }}
                    </div>
                    <div v-if="it.total_minute" class="time">
                      <div>考试时间：</div>
                      <div>{{ it.total_minute }}</div>
                      <div>分钟</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的模拟" name="mySimulation">
            <div class="paperDivList">
              <div
                v-for="(item, index) in userBookExamList"
                :key="item.id"
                style="position: relative"
              >
                <div
                  class="chengImgDiv"
                  @click.stop="showPaperListViewTwo(index)"
                  v-show="!item.isShow"
                >
                  <img class="avatar" :src="item.close_cover" alt="" />
                </div>

                <div
                  class="chengImgDivTwo"
                  @click.stop="showPaperListViewTwo(index)"
                  v-show="item.isShow"
                >
                  <img class="avatar" :src="item.cover" alt="" />
                </div>
                <div class="paperListView" v-show="item.isShow">
                  <div
                    v-for="it in item.paperList"
                    :key="it.id"
                    @click="
                      $router.push({
                        path: '/index-mockExam-simulationHistory',
                        query: { serial_code: it.serial_code },
                      })
                    "
                    class="paperList"
                  >
                    <div v-if="it.caption" class="title">
                      {{ it.caption }}
                    </div>
                    <div class="chengDiv">
                      <div v-if="it.total_minute" class="time">
                        <div>考试时间：</div>
                        <div>{{ it.total_minute }}</div>
                        <div>分钟</div>
                      </div>

                      <div v-if="it.total_score" class="time">
                        <div>最高：</div>
                        <div>{{ it.max_fraction }}</div>
                        <div>分</div>
                      </div>

                      <div v-if="it.max_fraction && it.number" class="time">
                        <div>平均：</div>
                        <div
                          v-if="Number.isInteger(it.max_fraction / it.number)"
                        >
                          {{ it.max_fraction / it.number }}
                        </div>

                        <div v-else>
                          {{ (it.max_fraction / it.number).toFixed(2) }}
                        </div>
                        <div>分</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import advBanner from "@components/common/advBanner";

export default {
  components: {
    advBanner,
  },
  data() {
    return {
      loading: false,
      activeName: "analogVolume",
      swiperList: [],
      examList: [],
      simulationList: [],
      bannerArr: [],
      userExamListsList: [],
      userBookExamList: [],
    };
  },

  async mounted() {
    this.loading = true;
    this.getBannerXin();
    this.getBanner();
    await this.getsimulationLists();
    await this.getExamLists();
    this.loading = false;
    await this.userExamLists();
    await this.userBookExam();
  },
  methods: {
    async userExamLists() {
      let res = await this.$findApi.userExamLists({
        title: this.title,
      });
      if (res.code == 200) {
        this.userExamListsList = res.data;
        this.userExamListsList.forEach((item) => {
          this.$set(item, "isShow", false);
        });
      }
    },

    async userBookExam() {
      let res = await this.$findApi.userBookExam({
        title: this.title,
      });
      if (res.code == 200) {
        this.userBookExamList = res.data;
        this.userBookExamList.forEach((item) => {
          this.$set(item, "isShow", false);
        });
      }
    },

    showPaperListViewTwo(index) {
      this.userBookExamList[index].isShow =
        !this.userBookExamList[index].isShow;
    },

    showPaperListView(index) {
      this.userExamListsList[index].isShow =
        !this.userExamListsList[index].isShow;
    },

    async getExamLists() {
      let res = await this.$newsApi.getExamLists({
        title: this.title,
      });
      if (res.code == 200) {
        this.examList = res.data;
      }
    },
    async getsimulationLists() {
      let res = await this.$newsApi.getsimulationLists({
        title: this.title,
      });
      if (res.code == 200) {
        this.simulationList = res.data;
      }
    },
    async getBanner() {
      let res = await this.$newsApi.getBanner({
        cate_id: 3,
      });
      if (res.code == 200) {
        this.swiperList = res.data;
      } else {
        this.xin.Toast(res.message);
      }
    },

    // 新闻和广告的路径跳转
    jumpTo(url, id, type) {
      if (type != 3) {
        this.$router.push({
          path: "/index-asdvertising",
          query: {
            is_adv: id,
          },
        });
      } else {
        window.open(url, "_blank");
      }
    },

    async getBannerXin() {
      let res = await this.$findApi.banner({
        position: 5,
      });
      if (res.code == 200) {
        this.bannerArr = res.data;
      }
    },

    handleClick(tab, event) {
      tab, event;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

::v-deep .el-tabs__header {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding-left: 20px;
    height: 59px;

    .el-tabs__nav {
      height: 100%;
      line-height: 59px;
    }

    .is-active {
      color: $--themeColor;
      transform: scale(1);
    }

    .el-tabs__header {
      font-size: 24px;
    }
  }
}

.page {
  .null {
    font-size: 16px;
    margin: 30px auto;
  }

  background: #f2f2f2;

  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-right: 10px;
    }

    .img {
      margin: 22px 0 30px 0;
    }

    > .analogVolume {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      width: 1200px;
      min-height: 400px;
      border-radius: 4px;
      font-size: 24px;
      color: #333333;

      > .tab {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        border-bottom: 1px solid #d9d9d9;

        > .tabAnalogvolume {
          display: block;
          margin: 0 50px 10px 30px;
          color: $--themeColor;
        }
      }

      .content {
        padding: 18px 30px 20px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between; //让自动分来

        .content-group {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          color: #8f8f8f;
          font-size: 24px;
          width: 560px;
          background: #ffffff;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          padding: 18px 30px;
          box-sizing: border-box;
          margin-bottom: 40px;

          > .group-title {
            color: #3aa5ea;
          }

          > .time {
            font-size: 14px;
            margin-top: 16px;

            .minute {
              color: #333333;
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  .paperDivList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }

  .chengImgDiv,
  .chengImgDivTwo {
    height: 270px;
    width: 560px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;

    .avatar {
      position: absolute;
      width: 100%;
      height: 90%;

      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .down {
      position: absolute;
      right: 20px;
      top: 110px;
      width: 32px;
      cursor: pointer;
    }
  }

  .chengImgDivTwo {
    height: 115px;
  }

  .paperListView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .paperList {
      padding-bottom: 20px;
      width: 100%;
      background: rgba(185, 253, 247, 0.17);
      border-bottom: 1px solid #e5e5e5;
    }

    .title {
      font-size: 24px;

      font-weight: 500;

      color: #666666;
      opacity: 1;
      margin: 22px 0 20px 20px;
    }

    .time {
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 20px;

      div:nth-child(1) {
        color: #8f8f8f;
      }

      div:nth-child(2),
      div:nth-child(3) {
        color: #000;
      }
    }
  }
}

.chengDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>
