var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"dkzc-box"},[_c('span',{staticClass:"title"},[_vm._v("模拟考试")]),_c('div',{staticClass:"img"},[(_vm.bannerArr.length > 0)?_c('advBanner',{attrs:{"bannerArr":_vm.bannerArr,"carouselWidth":"1200px","carouselHeight":"470px"}}):_vm._e()],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"analogVolume"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"模拟卷","name":"analogVolume"}},[_c('div',{staticClass:"paperDivList"},_vm._l((_vm.userExamListsList),function(item,index){return _c('div',{key:item.id,staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.isShow),expression:"!item.isShow"}],staticClass:"chengImgDiv",on:{"click":function($event){$event.stopPropagation();return _vm.showPaperListView(index)}}},[_c('img',{staticClass:"avatar",attrs:{"src":item.close_cover,"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],staticClass:"chengImgDivTwo",on:{"click":function($event){$event.stopPropagation();return _vm.showPaperListView(index)}}},[_c('img',{staticClass:"avatar",attrs:{"src":item.cover,"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],staticClass:"paperListView"},_vm._l((item.paperList),function(it){return _c('div',{key:it.id,staticClass:"paperList",on:{"click":function($event){return _vm.$router.push({
                      path: '/index-mockExam-answerSheet',
                      query: { serial_code: it.serial_code },
                    })}}},[(it.caption)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(it.caption)+" ")]):_vm._e(),(it.total_minute)?_c('div',{staticClass:"time"},[_c('div',[_vm._v("考试时间：")]),_c('div',[_vm._v(_vm._s(it.total_minute))]),_c('div',[_vm._v("分钟")])]):_vm._e()])}),0)])}),0)]),_c('el-tab-pane',{attrs:{"label":"我的模拟","name":"mySimulation"}},[_c('div',{staticClass:"paperDivList"},_vm._l((_vm.userBookExamList),function(item,index){return _c('div',{key:item.id,staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.isShow),expression:"!item.isShow"}],staticClass:"chengImgDiv",on:{"click":function($event){$event.stopPropagation();return _vm.showPaperListViewTwo(index)}}},[_c('img',{staticClass:"avatar",attrs:{"src":item.close_cover,"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],staticClass:"chengImgDivTwo",on:{"click":function($event){$event.stopPropagation();return _vm.showPaperListViewTwo(index)}}},[_c('img',{staticClass:"avatar",attrs:{"src":item.cover,"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],staticClass:"paperListView"},_vm._l((item.paperList),function(it){return _c('div',{key:it.id,staticClass:"paperList",on:{"click":function($event){return _vm.$router.push({
                      path: '/index-mockExam-simulationHistory',
                      query: { serial_code: it.serial_code },
                    })}}},[(it.caption)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(it.caption)+" ")]):_vm._e(),_c('div',{staticClass:"chengDiv"},[(it.total_minute)?_c('div',{staticClass:"time"},[_c('div',[_vm._v("考试时间：")]),_c('div',[_vm._v(_vm._s(it.total_minute))]),_c('div',[_vm._v("分钟")])]):_vm._e(),(it.total_score)?_c('div',{staticClass:"time"},[_c('div',[_vm._v("最高：")]),_c('div',[_vm._v(_vm._s(it.max_fraction))]),_c('div',[_vm._v("分")])]):_vm._e(),(it.max_fraction && it.number)?_c('div',{staticClass:"time"},[_c('div',[_vm._v("平均：")]),(Number.isInteger(it.max_fraction / it.number))?_c('div',[_vm._v(" "+_vm._s(it.max_fraction / it.number)+" ")]):_c('div',[_vm._v(" "+_vm._s((it.max_fraction / it.number).toFixed(2))+" ")]),_c('div',[_vm._v("分")])]):_vm._e()])])}),0)])}),0)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }